import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}
export interface Review {
  id: string
  type: string
  attributes: ReviewAttributes
}

export interface ReviewAttributes {
  id: number
  comment: string
  rating: number
  created_at: string
  updated_at: string
  listing_name: string
  logo_url: string
  name?: string;
  catalogue_id?:number;
  [key: string]: any;
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  listVendorData: ReviewAttributes[];
  ClaimListData: any[];
  tabCode: string;
  listTab: string;
  isOpen: boolean;
  selectedOption: string;
  myUrl: string;
  fileError: boolean;
  uploadCSVFiles: any[];
  counts: number | any;
  rowsPerPage: number | any;
  page: number | any;
  selectedSubTab:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ListVendorController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: string = "";
  getClaimCallId: string = "";
  fileInputRef: any = React.createRef();
  downloadListID: string = "";
  BulkUploadId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      listVendorData: [],
      ClaimListData: [],
      tabCode: "Suppliers",
      listTab: "Listings",
      isOpen: false,
      fileError: false,
      uploadCSVFiles: [],
      myUrl: "",
      selectedOption: "import",
      counts: 0,
      rowsPerPage: 5,
      page: 0,
      selectedSubTab:"Suppliers",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getListVendors();
    this.getClaimListing()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.page !== prevState.page || this.state.tabCode !== prevState.tabCode) {
      this.getListVendors();
      this.getClaimListing();
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.state.listTab == "Listings") {
        if (apiRequestId === this.getListCallId) {
          this.setState({ listVendorData: responseJson.catalogues });
          this.setState({ counts: responseJson?.meta?.total_count })
        }
      }
      if (apiRequestId === this.downloadListID) {
        this.setState({ myUrl: responseJson })
        this.downloadFile()
      }
      if (this.state.listTab == "Claims") {
        if (apiRequestId === this.getClaimCallId) {
          this.setState({ listVendorData: responseJson?.catalogues });
          this.setState({ counts: responseJson?.meta?.total_count })

        }
      }
    }
  }
  getListVendors = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListVendor}?page=${Number(this.state.page) + 1}&per_page=${this.state.rowsPerPage}&directory_name=${this.state.tabCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClaimListing = () => {
    const header = {
      token: localStorage.getItem("loginToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClaimCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClaimListingApi}?page=${Number(this.state.page) + 1}&per_page=${this.state.rowsPerPage}&directory_name=${this.state.tabCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabCode: newValue ,page:0,selectedSubTab:newValue})      
  };

  themeColorCodeDetect = () => {
    const { tabCode } = this.state;
    if (tabCode == "Software") {
      return '#F62B8D'
    } else if (tabCode == "Services") {
      return '#6BCAD3'
    } else  if (tabCode == "Suppliers") {
      return '#FFBB13'
    }
    else{
      return '#FFBB13'
    }
  };

  tabColorCodeDetect = (val: string) => {
    const { tabCode } = this.state;
    if (tabCode == val) {
      return this.themeColorCodeDetect()
    } else {
      return '#000'
    }
  }

  handleTabChange = (tab: string) => {
    this.setState({ listTab: tab , page:0, tabCode:this.state.selectedSubTab });
    if (tab === "Claims") {
      this.getClaimListing()
    }
    else if (tab === "Listings") {
      this.getListVendors()
    }
  }

  HandleNextSubmit = () => {
    this.handleUpdateFileUpload()
  }
  handleOptionChange = (event: any) => {
    this.setState({ selectedOption: event.target.value });
  }

  handleSelectRow = (event: any, rowData: ReviewAttributes, route: any) => {
    const newDataid = {
      ...rowData,
      directoryID: rowData?.directory_id,
      listngState: this.state.listTab,
      selectedTab: rowData?.directory_name,
    }
    setStorageData("currentListing", JSON.stringify(newDataid))
    removeStorageData("category_id");
    if(this.state.listTab=="Listings"){
      setStorageData("category_id", rowData?.id)
    }
    setStorageData("category_id", rowData?.id)
    setStorageData("listTab",this.state.listTab)
    let navigationTarget;
    if (route === "Suppliers") {
      navigationTarget = "SupplierCustomForm";
    } else if (route === "Software") {
      navigationTarget = "Customform4";
    } else if (route === "Services") {
      navigationTarget = "ServicesCustomForm";
    } else {
      navigationTarget = "SupplierCustomForm";
    }
    if (event.target.value === 1) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), navigationTarget);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: rowData });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
    else if (event.target.value === 2) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "PlanSelection");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: rowData });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    } else if (event.target.value === 3) {
      setStorageData("addons", true)
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "PlanDetail");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }

  }

  HandleNavigateAddNewList = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "JoinUsLandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: null });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleUpload = () => {
    this.fileInputRef.current?.click();
  }

  handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ uploadCSVFiles: [...this.state.uploadCSVFiles, file] });
      };
      reader.readAsDataURL(file);
    }
  };

  handleUpdateFileUpload = () => {
    const header = {
      token: localStorage.getItem("loginToken"),
    };


    let formdata = new FormData();
    formdata.append("file", this.state.uploadCSVFiles[0], this.state.uploadCSVFiles[0]?.name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.BulkUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkUpload
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isOpen: false });
    this.getListVendors()
  }

  handleDownload = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };
    const httpBody = {}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downloadCSV
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  downloadFile = async () => {
    const { myUrl }: any = this.state;
    try {
      const a = document.createElement("a");
      a.href = myUrl.url;
      const url = new URL(myUrl.url);
      const fileName: any = url.pathname.split('/').pop();
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
    }
  };

  handleOpenImport = () => {
    this.setState({ isOpen: true })
  }

  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ page: 0 });
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };



  // Customizable Area End
}
